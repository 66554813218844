
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/iad/shop/[[...seopath]]",
      function () {
        return require("private-next-pages/iad/shop/[[...seopath]].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/iad/shop/[[...seopath]]"])
      });
    }
  