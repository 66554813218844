import { getDealerProfileBySlug } from '@bbx/ad-in/organisation/api/dealerProfileApiClient'
import { DealerProfileContainer } from '@bbx/ad-in/organisation/components/DealerProfileContainer'
import { DealerTabs } from '@bbx/ad-in/organisation/components/DealerTabs'
import { DealerProfile } from '@bbx/ad-in/organisation/types/DealerProfile'
import { sanitizeAdDescription } from '@bbx/common/lib/xss'
import { getAlertSearchResult, getSeoSearchResult } from '@bbx/search-journey/common/api/searchApiClient'
import { getInitialViewMode, useViewMode, ViewMode } from '@bbx/search-journey/common/lib/viewModeHelper'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { DesktopBreadcrumbs } from '@bbx/search-journey/sub-domains/search/components/common/result-list/DesktopBreadcrumbs/DesktopBreadcrumbs'
import { BapResultListContainer } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/result-list/BapResultListContainer'
import type { Layout } from '@wh/common/chapter/components/Layouts/Layout'
import { SkyscraperLayout } from '@wh/common/chapter/components/Layouts/SkyscraperLayout'
import { staticRelativeCanonicals } from '@wh/common/chapter/hocs/staticRelativeCanonicals'
import { wrapInApiErrorIfNecessary } from '@wh/common/chapter/lib/errors'
import { concatPathWithQueryParams, getFirstString } from '@wh/common/chapter/lib/urlHelpers'
import { verticalIdMap, verticals } from '@wh/common/chapter/types/verticals'
import { GetServerSidePropsContext, GetServerSidePropsResult, NextPage } from 'next'
import React, { Fragment } from 'react'

type DealerProfileBySlugProps = {
    verticalId: number
    searchResult: SearchResult
    dealerProfile: DealerProfile
    defaultViewMode: ViewMode
}

export const DealerProfilePageBySlug: NextPage<DealerProfileBySlugProps> & { Layout: typeof Layout } = (props) => {
    const [viewMode, setViewMode] = useViewMode(props.defaultViewMode)

    const companyDescription =
        props.dealerProfile.organisation.storeDescription && sanitizeAdDescription(props.dealerProfile.organisation.storeDescription)

    return (
        <Fragment>
            <DesktopBreadcrumbs breadcrumbs={breadcrumbs} />

            <DealerProfileContainer organisation={props.dealerProfile.organisation} />
            <DealerTabs
                dealerProfile={props.dealerProfile}
                resultListLabel="Angebot"
                companyPhoto={props.dealerProfile.organisation.mainLogoUrls?.large}
                companyDescription={companyDescription}
                resultListContainer={
                    <BapResultListContainer
                        searchResult={props.searchResult}
                        pageType="dealer-profile"
                        viewMode={viewMode}
                        setViewMode={setViewMode}
                    />
                }
            />
        </Fragment>
    )
}

export const getServerSideProps = async (
    context: GetServerSidePropsContext,
): Promise<GetServerSidePropsResult<DealerProfileBySlugProps>> => {
    const { query, req, resolvedUrl } = context

    const { seopath, alertId, ...remainingQuery } = query
    const path = ['/shop', ...((seopath ?? []) as string[])].join('/')
    const searchAgentId = getFirstString(alertId)
    const slug = seopath?.[0]

    if (!slug) {
        return { notFound: true }
    }

    try {
        const concatenatedPath = concatPathWithQueryParams(path, remainingQuery)
        const searchResultPromise = searchAgentId ? getAlertSearchResult(query, req) : getSeoSearchResult(concatenatedPath, req)

        const dealerProfilePromise = getDealerProfileBySlug(slug, verticals.BAP, req)
        const [searchResult, dealerProfile] = await Promise.all([searchResultPromise, dealerProfilePromise])

        // Redirect to the current Personalized Shop URL if slug of organisation is out-dated
        if (dealerProfile.organisation.slug) {
            if (!resolvedUrl.startsWith(`/iad/shop/${dealerProfile.organisation.slug}`)) {
                const redirectUrl = concatPathWithQueryParams(`/iad/shop/${dealerProfile.organisation.slug}`, {
                    alertId,
                    ...remainingQuery,
                })
                return { redirect: { destination: redirectUrl, permanent: true } }
            }
        }

        const defaultViewMode = getInitialViewMode(searchResult, req)
        return { props: { verticalId: verticalIdMap.BAP, searchResult, dealerProfile, defaultViewMode } }
    } catch (error) {
        const apiError = wrapInApiErrorIfNecessary(error)

        if (apiError.statusCode && apiError.statusCode >= 400 && apiError.statusCode <= 499) {
            return { notFound: true }
        }

        throw apiError
    }
}

const breadcrumbs = [
    { displayName: 'Startseite', seoUrl: staticRelativeCanonicals.Home },
    { displayName: 'Marktplatz', seoUrl: staticRelativeCanonicals.BapHome },
    { displayName: 'Händlerübersicht', seoUrl: staticRelativeCanonicals.BapDealerResultList },
    { displayName: 'Händlerprofil', seoUrl: '' },
]

DealerProfilePageBySlug.Layout = SkyscraperLayout

export default DealerProfilePageBySlug
